import React, { useEffect, useState } from 'react';
import Spinner from "../../../../components/Spinner/Spinner";
import styles from "./HHSReport.module.less";
import GaugeChart, { GaugeChartData } from "./GaugeChart";
import HabitScore, { HistoryChartData } from "./HabitScore";
import ContributingHabits, { ContributingHabitsData } from "./ContributingHabits";
import HistoryChart, { HistoryChartDataWithZones } from "./HistoryChart";
import InnerageAffectingHabits from "./InnerageAffectingHabits";
import reportHubApi from "../../../../services/Customer/reportHubApi";

type HHRDataType = {
  charts: {
    type: string;
    name: string;
    params: {
      value?: number; // For gauge_chart
      min_value?: number; // For gauge_chart
      max_value?: number; // For gauge_chart
      x?: string[]; // For history_chart and history_with_zones_chart
      y?: number[]; // For history_chart and history_with_zones_chart
      min_x?: string; // For history_chart and history_with_zones_chart
      max_x?: string; // For history_chart and history_with_zones_chart
      min_y?: number; // For history_chart and history_with_zones_chart
      max_y?: number; // For history_chart and history_with_zones_chart
      y_label?: string; // For history_with_zones_chart
      zones?:{
        range: [number, number];
        weight: number;
        color: string;
      }[]; // For history_with_zones_chart
      y_ticks?: any[];
      habits?: {
        habit_name: string;
        normalized_value: number;
      }[]; // For contributing_habits_chart
    };
  }[];
  texts: {
    progress_statement_text: { value: string };
    lowest_habit_negative_bool: { value: boolean };
    top_habit_w_InnerAge_string: { value: string };
    top_habit_w_InnerAge_biomarkers: { value: string[] };
    lowest_habit_w_InnerAge_string: { value: string };
    lowest_habit_w_InnerAge_biomarkers: { value: string[] };
    lowest_habit_string: { value: string };
    lowest_habit_alt_text_formatted_name: { value: string };
    lowest_habit_quick_tips_list: { value: string[] };
    has_journal_questions_bool: { value: boolean };
  };
  report_data?: any;
  score_percentile?: number,
  habit_score_history_dict?: any,
  lowest_habit?: string,
  absolute_score_level?: string,
  HHS_status?: any,
  mostRecentWeek?: any,
};

const data: HHRDataType = {
  charts: [
    {
      type: "gauge_chart",
      name: "score_percentile_chart",
      params: {
        value: 47,
        min_value: 0,
        max_value: 100,
      },
    },
    {
      type: "history_chart",
      name: "habit_score_history_chart",
      params: {
        x: [
          "2025-02-17",
          "2025-02-24",
          "2025-03-03",
          "2025-03-10",
          "2025-03-17",
          "2025-03-24",
        ],
        y: [
          33,
          47,
          47,
          40,
          47,
          47,
        ],
        min_x: "2025-02-17",
        max_x: "2025-03-24",
        min_y: 0,
        max_y: 100,
      },
    },
    {
      type: "contributing_habits_chart",
      name: "contributing_habits_chart",
      params: {
        habits: [
          {
            habit_name: "Exercise Hours",
            normalized_value: 1.0,
          },
          {
            habit_name: "Sleep Duration",
            normalized_value: 1.0,
          },
          {
            habit_name: "Bed Time Consistency",
            normalized_value: 0.75,
          },
          {
            habit_name: "Sleep Efficiency",
            normalized_value: 0.38,
          },
          {
            habit_name: "VO2 Max",
            normalized_value: 0.26,
          },
          {
            habit_name: "REM Sleep Percentage",
            normalized_value: 0.01,
          },
          {
            habit_name: "Resting Heart Rate",
            normalized_value: 0.01,
          },
          {
            habit_name: "Deep Sleep Percentage",
            normalized_value: -0.23,
          },
          {
            habit_name: "Step Count",
            normalized_value: -0.98,
          },
        ],
      },
    },
    {
      type: "history_with_zones_chart",
      name: "Steps_chart",
      params: {
        zones: [
          {
            range: [
              0,
              2000,
            ],
            weight: -4,
            color: "",
          },
          {
            range: [
              2000,
              4000,
            ],
            weight: -2,
            color: "",
          },
          {
            range: [
              4000,
              6000,
            ],
            weight: -1,
            color: "",
          },
          {
            range: [
              6000,
              8000,
            ],
            weight: 0,
            color: "#7ee07e",
          },
          {
            range: [
              8000,
              10000,
            ],
            weight: 1,
            color: "#54c054",
          },
          {
            range: [
              10000,
              12000,
            ],
            weight: 2,
            color: "#29a029",
          },
          {
            range: [
              12000,
              100000,
            ],
            weight: 3,
            color: "#008000",
          },
        ],
        x: [
          "2025-02-17",
          "2025-02-24",
          "2025-03-03",
          "2025-03-10",
          "2025-03-17",
          "2025-03-24",
        ],
        y: [
          3830.71,
          4754.0,
          4689.57,
          5281.29,
          7282.6,
          4416.43,
        ],
        min_x: "2025-02-17",
        max_x: "2025-03-24",
        min_y: 2795.14,
        max_y: 10035.57,
        y_ticks: [
          "2000.0",
          "3000.0",
          "4000.0",
          "5000.0",
          "6000.0",
          "7000.0",
          "8000.0",
          "9000.0",
          "10000.0",
          "11000.0",
        ],
        y_label: "Step Count",
      },
    },
  ],
  texts: {
    progress_statement_text: {
      value: "Good job on your habits this week!",
    },
    lowest_habit_negative_bool: {
      value: true,
    },
    top_habit_w_InnerAge_string: {
      value: "Exercise Hours",
    },
    top_habit_w_InnerAge_biomarkers: {
      value: [
        "HDL",
      ],
    },
    lowest_habit_w_InnerAge_string: {
      value: "Step Count",
    },
    lowest_habit_w_InnerAge_biomarkers: {
      value: [
        "LDL",
        "Triglycerides",
        "HbA1c",
        "hsCRP",
        "White Blood Cells",
      ],
    },
    lowest_habit_string: {
      value: "Steps",
    },
    lowest_habit_alt_text_formatted_name: {
      value: "Step Count",
    },
    lowest_habit_quick_tips_list: {
      value: [
        "Take 1,000 steps beyond your average each day\r",
        "Add a 30 minute walk into your daily routine, ideally after a meal",
      ],
    },
    has_journal_questions_bool: {
      value: false,
    },
  },
  report_data: {
    customerId: 385,
    "habit-scores": {
      "2025-03-24": {
        contributions: {
          "Exercise_Hours-TRND": 0.0,
          "Exercise_Hours-EWMA": 0.8,
          "RHR-TRND": 0.0,
          "RHR-EWMA": 0.0,
          "Sleep_Efficiency-TRND": 0.0,
          "Sleep_Efficiency-EWMA": 0.3,
          "Sleep_Duration-TRND": 0.0,
          "Sleep_Duration-EWMA": 0.8,
          "Deep_Percentage-TRND": 0.0,
          "Deep_Percentage-EWMA": -0.2,
          "Bed_Time_STD-TRND": 0.0,
          "Bed_Time_STD-EWMA": 0.6,
          "Steps-TRND": 0.0,
          "Steps-EWMA": -0.8,
          "REM_Percentage-TRND": 0.0,
          "REM_Percentage-EWMA": 0.0,
          "Vo2_Max-TRND": 0.0,
          "Vo2_Max-EWMA": 0.2,
        },
        score: 1.7,
        stimuliSubscore: 1.4,
        trends: {
          Exercise_Hours: "Same",
          RHR: "Same",
          Sleep_Efficiency: "Same",
          Sleep_Duration: "Same",
          Deep_Percentage: "Same",
          Bed_Time_STD: "Same",
          Steps: "Same",
          REM_Percentage: "Same",
          Vo2_Max: "Same",
        },
        combinedContributions: {
          Exercise_Hours: 0.8,
          RHR: 0.0,
          Sleep_Efficiency: 0.3,
          Sleep_Duration: 0.8,
          Deep_Percentage: -0.2,
          Bed_Time_STD: 0.6,
          Steps: -0.8,
          REM_Percentage: 0.0,
          Vo2_Max: 0.2,
        },
        responseSubscore: 0.3,
      },
      "2025-03-17": {
        contributions: {
          "Exercise_Hours-TRND": 0.0,
          "Exercise_Hours-EWMA": 0.8,
          "RHR-TRND": 0.2,
          "RHR-EWMA": 0.0,
          "Sleep_Efficiency-TRND": -0.2,
          "Sleep_Efficiency-EWMA": 0.1,
          "Sleep_Duration-TRND": -0.8,
          "Sleep_Duration-EWMA": 0.8,
          "Deep_Percentage-TRND": 0.0,
          "Deep_Percentage-EWMA": -0.2,
          "Bed_Time_STD-TRND": 0.0,
          "Bed_Time_STD-EWMA": 0.6,
          "Steps-TRND": 0.8,
          "Steps-EWMA": -0.8,
          "REM_Percentage-TRND": 0.2,
          "REM_Percentage-EWMA": 0.0,
          "Vo2_Max-TRND": 0.0,
          "Vo2_Max-EWMA": 0.2,
        },
        score: 1.7,
        stimuliSubscore: 1.4,
        trends: {
          Exercise_Hours: "Same",
          RHR: "Decreasing",
          Sleep_Efficiency: "Decreasing",
          Sleep_Duration: "Increasing",
          Deep_Percentage: "Same",
          Bed_Time_STD: "Same",
          Steps: "Increasing",
          REM_Percentage: "Decreasing",
          Vo2_Max: "Same",
        },
        combinedContributions: {
          Exercise_Hours: 0.8,
          RHR: 0.2,
          Sleep_Efficiency: -0.1,
          Sleep_Duration: 0.0,
          Deep_Percentage: -0.2,
          Bed_Time_STD: 0.6,
          Steps: 0.0,
          REM_Percentage: 0.2,
          Vo2_Max: 0.2,
        },
        responseSubscore: 0.30000000000000004,
      },
      "2025-03-10": {
        contributions: {
          "Exercise_Hours-TRND": 0.0,
          "Exercise_Hours-EWMA": 0.8,
          "RHR-TRND": -0.2,
          "RHR-EWMA": 0.0,
          "Sleep_Efficiency-TRND": 0.0,
          "Sleep_Efficiency-EWMA": 0.3,
          "Sleep_Duration-TRND": 0.8,
          "Sleep_Duration-EWMA": -0.2,
          "Deep_Percentage-TRND": 0.0,
          "Deep_Percentage-EWMA": -0.2,
          "Bed_Time_STD-TRND": 0.0,
          "Bed_Time_STD-EWMA": 0.6,
          "Steps-TRND": 0.0,
          "Steps-EWMA": -0.8,
          "REM_Percentage-TRND": 0.0,
          "REM_Percentage-EWMA": 0.0,
          "Vo2_Max-TRND": 0.0,
          "Vo2_Max-EWMA": 0.2,
        },
        score: 1.3,
        stimuliSubscore: 1.2000000000000002,
        trends: {
          Exercise_Hours: "Same",
          RHR: "Increasing",
          Sleep_Efficiency: "Same",
          Sleep_Duration: "Decreasing",
          Deep_Percentage: "Same",
          Bed_Time_STD: "Same",
          Steps: "Same",
          REM_Percentage: "Same",
          Vo2_Max: "Same",
        },
        combinedContributions: {
          Exercise_Hours: 0.8,
          RHR: -0.2,
          Sleep_Efficiency: 0.3,
          Sleep_Duration: 0.6,
          Deep_Percentage: -0.2,
          Bed_Time_STD: 0.6,
          Steps: -0.8,
          REM_Percentage: 0.0,
          Vo2_Max: 0.2,
        },
        responseSubscore: 0.09999999999999998,
      },
      "2025-03-03": {
        contributions: {
          "Exercise_Hours-TRND": 0.0,
          "Exercise_Hours-EWMA": 0.8,
          "RHR-TRND": 0.0,
          "RHR-EWMA": 0.0,
          "Sleep_Efficiency-TRND": 0.0,
          "Sleep_Efficiency-EWMA": 0.3,
          "Sleep_Duration-TRND": 0.0,
          "Sleep_Duration-EWMA": 0.8,
          "Deep_Percentage-TRND": 0.0,
          "Deep_Percentage-EWMA": -0.2,
          "Bed_Time_STD-TRND": 0.0,
          "Bed_Time_STD-EWMA": 0.6,
          "Steps-TRND": 0.0,
          "Steps-EWMA": -0.8,
          "REM_Percentage-TRND": 0.0,
          "REM_Percentage-EWMA": 0.0,
          "Vo2_Max-TRND": 0.0,
          "Vo2_Max-EWMA": 0.2,
        },
        score: 1.7,
        stimuliSubscore: 1.4,
        trends: {
          Exercise_Hours: "Same",
          RHR: "Same",
          Sleep_Efficiency: "Same",
          Sleep_Duration: "Same",
          Deep_Percentage: "Same",
          Bed_Time_STD: "Same",
          Steps: "Same",
          REM_Percentage: "Same",
          Vo2_Max: "Same",
        },
        combinedContributions: {
          Exercise_Hours: 0.8,
          RHR: 0.0,
          Sleep_Efficiency: 0.3,
          Sleep_Duration: 0.8,
          Deep_Percentage: -0.2,
          Bed_Time_STD: 0.6,
          Steps: -0.8,
          REM_Percentage: 0.0,
          Vo2_Max: 0.2,
        },
        responseSubscore: 0.3,
      },
      "2025-02-24": {
        contributions: {
          "Exercise_Hours-TRND": 0.0,
          "Exercise_Hours-EWMA": 0.8,
          "RHR-TRND": 0.0,
          "RHR-EWMA": 0.0,
          "Sleep_Efficiency-TRND": 0.0,
          "Sleep_Efficiency-EWMA": 0.3,
          "Sleep_Duration-TRND": 0.0,
          "Sleep_Duration-EWMA": 0.8,
          "Deep_Percentage-TRND": 0.0,
          "Deep_Percentage-EWMA": -0.2,
          "Bed_Time_STD-TRND": 0.0,
          "Bed_Time_STD-EWMA": 0.6,
          "Steps-TRND": 0.0,
          "Steps-EWMA": -0.8,
          "REM_Percentage-TRND": 0.0,
          "REM_Percentage-EWMA": 0.0,
          "Vo2_Max-TRND": 0.0,
          "Vo2_Max-EWMA": 0.2,
        },
        score: 1.7,
        stimuliSubscore: 1.4,
        trends: {
          Exercise_Hours: "Same",
          RHR: "Same",
          Sleep_Efficiency: "Same",
          Sleep_Duration: "Same",
          Deep_Percentage: "Same",
          Bed_Time_STD: "Same",
          Steps: "Same",
          REM_Percentage: "Same",
          Vo2_Max: "Same",
        },
        combinedContributions: {
          Exercise_Hours: 0.8,
          RHR: 0.0,
          Sleep_Efficiency: 0.3,
          Sleep_Duration: 0.8,
          Deep_Percentage: -0.2,
          Bed_Time_STD: 0.6,
          Steps: -0.8,
          REM_Percentage: 0.0,
          Vo2_Max: 0.2,
        },
        responseSubscore: 0.3,
      },
      "2025-02-17": {
        contributions: {
          "Exercise_Hours-TRND": 0.0,
          "Exercise_Hours-EWMA": 0.8,
          "RHR-TRND": 0.0,
          "RHR-EWMA": 0.0,
          "Sleep_Efficiency-TRND": 0.0,
          "Sleep_Efficiency-EWMA": 0.3,
          "Sleep_Duration-TRND": 0.0,
          "Sleep_Duration-EWMA": 0.8,
          "Deep_Percentage-TRND": 0.0,
          "Deep_Percentage-EWMA": -0.2,
          "Bed_Time_STD-TRND": 0.0,
          "Bed_Time_STD-EWMA": 0.6,
          "Steps-TRND": -0.8,
          "Steps-EWMA": -0.8,
          "REM_Percentage-TRND": 0.0,
          "REM_Percentage-EWMA": 0.0,
          "Vo2_Max-TRND": 0.0,
          "Vo2_Max-EWMA": 0.2,
        },
        score: 0.9,
        stimuliSubscore: 0.6000000000000001,
        trends: {
          Exercise_Hours: "Same",
          RHR: "Same",
          Sleep_Efficiency: "Same",
          Sleep_Duration: "Same",
          Deep_Percentage: "Same",
          Bed_Time_STD: "Same",
          Steps: "Decreasing",
          REM_Percentage: "Same",
          Vo2_Max: "Same",
        },
        combinedContributions: {
          Exercise_Hours: 0.8,
          RHR: 0.0,
          Sleep_Efficiency: 0.3,
          Sleep_Duration: 0.8,
          Deep_Percentage: -0.2,
          Bed_Time_STD: 0.6,
          Steps: -1.6,
          REM_Percentage: 0.0,
          Vo2_Max: 0.2,
        },
        responseSubscore: 0.3,
      },
    },
    habitScoreInformation: {},
    habitAverages: {
      "2025-02-17": {
        RHR: 67.28571428571429,
        Vo2_Max: 37.0,
        Steps: 3830.714285714286,
        Exercise_Hours: 0.6937037037037038,
        Sleep_Efficiency: 0.8925667576561488,
        Sleep_Duration: 7.011904761904761,
        Deep_Percentage: 0.0920189091054506,
        REM_Percentage: 0.1877162321384922,
        Bed_Time_STD: 60.574474436102875,
      },
      "2025-02-24": {
        RHR: 67.5,
        Vo2_Max: 37.0,
        Steps: 4754.0,
        Exercise_Hours: 0.49777777777777776,
        Sleep_Efficiency: 0.9102748455798154,
        Sleep_Duration: 6.973611111111111,
        Deep_Percentage: 0.07022075600010656,
        REM_Percentage: 0.19550856592722002,
        Bed_Time_STD: 41.818013714319235,
      },
      "2025-03-03": {
        RHR: 67.57142857142857,
        Vo2_Max: 37.0,
        Steps: 4689.571428571428,
        Exercise_Hours: 0.7528333333333334,
        Sleep_Efficiency: 0.8991384578572086,
        Sleep_Duration: 7.103571428571429,
        Deep_Percentage: 0.06786869118024805,
        REM_Percentage: 0.11202424737371557,
        Bed_Time_STD: 56.20769494037946,
      },
      "2025-03-10": {
        RHR: 68.14285714285714,
        Vo2_Max: 37.0,
        Steps: 5281.285714285715,
        Exercise_Hours: 0.7205555555555556,
        Sleep_Efficiency: 0.9126899255690015,
        Sleep_Duration: 6.464285714285714,
        Deep_Percentage: 0.07236190661036591,
        REM_Percentage: 0.18376333074751489,
        Bed_Time_STD: 32.84883109917234,
      },
      "2025-03-17": {
        RHR: 67.0,
        Vo2_Max: 37.0,
        Steps: 7282.6,
        Exercise_Hours: 0.6434027777777778,
        Sleep_Efficiency: 0.8800496108150879,
        Sleep_Duration: 8.153333333333332,
        Deep_Percentage: 0.06578289961173801,
        REM_Percentage: 0.0997336489479195,
        Bed_Time_STD: 34.55997290367323,
      },
      "2025-03-24": {
        RHR: 67.0,
        Vo2_Max: 37.0,
        Steps: 4416.428571428572,
        Exercise_Hours: 0.6116666666666667,
        Sleep_Efficiency: 0.9100416021229875,
        Sleep_Duration: 7.219047619047619,
        Deep_Percentage: 0.08400447895390581,
        REM_Percentage: 0.14313816737865986,
        Bed_Time_STD: 36.538144097643574,
      },
    },
    scalingZones: {
      Exercise_Hours: {
        scalingZones: [
          0.5,
          1,
          1.5,
          2,
          2.5,
          3,
          3.5,
          4,
          4.5,
          5,
          5.5,
          6,
          6.5,
          7,
          7.5,
          8,
          8.5,
        ],
        scalingZoneWeights: [
          0,
          1,
          2,
          3,
          4,
          5,
          6,
          7,
          8,
          9,
          10,
          11,
          12,
          13,
          14,
          15,
          16,
        ],
      },
      RHR: {
        scalingZones: [
          30,
          63.05,
          90,
        ],
        scalingZoneWeights: [
          -1,
          1,
          0,
          -1,
        ],
      },
      Sleep_Efficiency: {
        scalingZones: [
          0.85,
          0.9,
        ],
        scalingZoneWeights: [
          -1,
          0.5,
          1.5,
        ],
      },
      Sleep_Duration: {
        scalingZones: [
          6,
          7,
          8,
          10,
        ],
        scalingZoneWeights: [
          -1,
          -0.25,
          1,
          -0.25,
          -1,
        ],
      },
      Deep_Percentage: {
        scalingZones: [
          0.08,
          0.13,
          0.28,
        ],
        scalingZoneWeights: [
          -1,
          0,
          1,
          0,
        ],
      },
      Bed_Time_STD: {
        scalingZones: [
          30,
          60,
        ],
        scalingZoneWeights: [
          1.5,
          0.75,
          -1,
        ],
      },
      Steps: {
        scalingZones: [
          2000,
          4000,
          6000,
          8000,
          10000,
          12000,
        ],
        scalingZoneWeights: [
          -4,
          -2,
          -1,
          0,
          1,
          2,
          3,
        ],
      },
      REM_Percentage: {
        scalingZones: [
          0.12,
          0.2,
          0.35,
        ],
        scalingZoneWeights: [
          -1,
          0,
          1,
          0,
        ],
      },
      Vo2_Max: {
        scalingZones: [
          24,
          26.9,
          30,
        ],
        scalingZoneWeights: [
          -1,
          -0.5,
          0,
          1,
        ],
      },
    },
    HHS_status: 200,
    columns_removed: [],
    Provider_Tag: "FITBIT",
    mostRecentWeek: 13,
    Days_Per_Week_With_Steps: 6.5,
  },
  score_percentile: 47,
  habit_score_history_dict: {
    "2025-02-17": 0.9,
    "2025-02-24": 1.7,
    "2025-03-03": 1.7,
    "2025-03-10": 1.3,
    "2025-03-17": 1.7,
    "2025-03-24": 1.7,
  },
  lowest_habit: "Steps",
  absolute_score_level: "Stable",
  HHS_status: 200,
  mostRecentWeek: 13,
};

// The HHSReport component is structured as follows:
// Gauge Chart: Displays the user's current habit score percentile.
// Habit Score History Chart: Shows the trend of habit scores over time.
// Contributing Habits: Lists habits contributing positively or negatively to the user's score.
// InnerAge Affecting Habits: Highlights habits impacting the user's biological age.
// Pick Your Habit Goal: Provides actionable tips to improve the lowest-scoring habit.
// BedTime History Chart: Displays historical data for bedtime consistency with zones.
// About This Report: Explains the purpose of the report.
// Want More Habits?: Encourages users to fill out their daily journal for more insights.

const HHSReport = (props: { match: any }) => {
  const { reportToken } = props.match.params;
  const [reportData, setReportData] = useState<any>(null);

  useEffect(() => {
    (async () => {
      const reportDataResponse = await reportHubApi.getReportView(reportToken);
      setReportData(reportDataResponse);
    })();
  }, []);

  if (!reportData) {
    return <Spinner show mini/>;
  }

  const gaugeChartData: GaugeChartData | null = reportData.charts.find((item: any) => item.type === 'gauge_chart') as GaugeChartData | null;
  const historyChartData: HistoryChartData | null = reportData.charts.find((item: any) => item.type === 'history_chart') as HistoryChartData | null;
  const historyChartDataWithZones: HistoryChartDataWithZones | null = reportData.charts.find((item: any) => item.type === 'history_with_zones_chart') as HistoryChartDataWithZones | null;
  const contributingHabitsData: ContributingHabitsData | null = reportData.charts.find((item: any) => item.type === 'contributing_habits_chart') as ContributingHabitsData | null;

  return (
    <div className={styles.report}>
      <div className={styles.container}>

        {/* Displays the user's habit score percentile. */}
        {gaugeChartData && (
        <GaugeChart
          data={gaugeChartData}
          text={reportData.texts.progress_statement_text.value}
        />
        )}

        {/* Visualizes the trend of habit scores over time. */}
        {historyChartData && (
        <HabitScore dataHistoryChart={historyChartData}/>
        )}

        {/* Lists habits contributing positively or negatively to the user's score. */}
        {contributingHabitsData && (
        <ContributingHabits data={contributingHabitsData}/>
        )}

        {/* Highlights habits impacting the user's biological age. */}
        {data && contributingHabitsData && (
        <InnerageAffectingHabits data={data} contributingHabitsData={contributingHabitsData}/>
        )}

        {/* Displays habits to improve. */}
        <div className={`${styles.box} ${styles.boxHabits}`}>
          <p className={styles.boxTitle}>Pick your habit goal</p>
          <p className={styles.boxText}>
            Improving your
            {' '}
            <span className={styles.textSemibold}>
              {data.texts.lowest_habit_alt_text_formatted_name?.value}
            </span>
            {' '}
            is the best way to boost your
            habit score for next week. Choose the
            {' '}
            <span className={styles.textUnderlined}>one goal</span>
            {' '}
            you’re most likely to hit.
          </p>
          <div className={styles.divider} />
          {data.texts.lowest_habit_quick_tips_list.value && (
          <ol className={styles.habitsList} id="habitsList">
            {data.texts.lowest_habit_quick_tips_list.value.map((item) => <li key={item}>{item}</li>)}
          </ol>
          )}
        </div>

        {/* Displays historical data for bedtime consistency with performance zones. */}
        {historyChartDataWithZones && (
        <HistoryChart
          historyChartDataWithZones={historyChartDataWithZones}
        />
        )}

        {/* static components */}
        <div className={`${styles.boxInfo} ${styles.box} ${styles.boxGray}`}>
          <div className={styles.flexWrap}>
            <svg
              className={styles.boxInfoIcon}
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.4168 10.1313C16.6117 10.1313 15.9588 9.47845 15.9588 8.67332C15.9588 7.8679 16.6117 7.21501 17.4168 7.21501C18.2222 7.21501 18.8751 7.8679 18.8751 8.67332C18.8751 9.47845 18.2222 10.1313 17.4168 10.1313ZM15.5526 23.7071C14.1729 24.3741 12.0391 23.8001 12.4115 22.4109L14.4239 14.9006C15.1279 12.2732 13.1921 13.1346 12.3248 13.5154C12.8552 12.7181 13.5893 12.0773 14.4511 11.6599C15.8311 10.9928 17.9645 11.5665 17.5922 12.9561L15.58 20.4666C14.8757 23.0938 16.8116 22.2326 17.6788 21.8516C17.1484 22.6489 16.4144 23.2896 15.5526 23.7071ZM15.6 2.60001C8.42022 2.60001 2.59998 8.42025 2.59998 15.6V28.6H15.6C22.7794 28.6 28.6 22.7798 28.6 15.6C28.6 8.42025 22.7794 2.60001 15.6 2.60001Z"
                fill="#005550"
              />
            </svg>
            <div>
              <p className={styles.boxTitle}>
                About this report:
              </p>
              <p className={styles.boxText}>
                Healthy habits are the bedrock of health,
                and this report aims to give you one meaningful new habit each week.
                You can always do more, but small steps often work best.
                By committing to one achievable challenge each week,
                you'll be more likely to build long-term habits that stick.
              </p>
            </div>
          </div>
        </div>
        <div className={`${styles.box} ${styles.boxLast}`}>
          <p className={styles.boxTitle}>
            Want more habits to be included in your score?
          </p>
          <p className={styles.boxText}>
            Be sure to fill out your daily journal.
            You’ll find it at the bottom of the home screen in your InsideTracker app.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HHSReport;
