import React from 'react';
import styles from "./HHSReport.module.less";
import { ContributingHabitsData } from "./ContributingHabits";

const InnerageAffectingHabits = (props: {
  data: any,
  contributingHabitsData: ContributingHabitsData
}) => {
  const { data, contributingHabitsData } = props;

  return (
    <div className={`${styles.habitsAffect} ${styles.box} ${styles.boxGreen}`}>
      <p className={styles.boxTitle}>
        How your top and bottom habits are potentially affecting your InnerAge
      </p>
      {data && contributingHabitsData && (
        <>
          <div className={styles.habitsAffectInfoWrap}>
            <div className={styles.iconPlus}>+</div>
            <div className={styles.habitsAffectInfo} id="habitsAffect-good">
              <p className={styles.habitsAffectInfoTitle}>
                {contributingHabitsData.params.habits[0].habit_name}
                {' '}
                <span>
                  may positively affect:
                </span>
              </p>
              {data.texts.top_habit_w_InnerAge_biomarkers && (
              <p className={styles.habitsAffectInfoText}>
                {data.texts.top_habit_w_InnerAge_biomarkers.value.join(', ')}
              </p>
              )}
            </div>
          </div>
          <div className={styles.habitsAffectInfoWrap}>
            <div className={styles.iconMinus}>&ndash;</div>
            <div className={styles.habitsAffectInfo} id="habitsAffect-bad">
              <p className={styles.habitsAffectInfoTitle}>
                {contributingHabitsData.params.habits[contributingHabitsData.params.habits.length - 1].habit_name}
                {' '}
                <span>
                  may negatively impact:
                </span>
              </p>
              {data.texts.lowest_habit_w_InnerAge_biomarkers && (
              <p className={styles.habitsAffectInfoText}>
                {data.texts.lowest_habit_w_InnerAge_biomarkers.value.join(', ')}
              </p>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InnerageAffectingHabits;
